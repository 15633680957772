import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero/Hero"
import Gallery from "../components/Projects/Gallery"
const projects = ({ data }) => {
  return (
    <Layout>
      <SEO title="Projects" />
      <Hero
        className="projectsHero"
        img={data.indexBanner.childImageSharp.fluid}
      />
      <Gallery />
    </Layout>
  )
}

export default projects
export const query = graphql`
  query {
    indexBanner: file(relativePath: { eq: "banner2.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 2160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
