import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

const Card = ({ card }) => {
  /*   const { adress, picture, title, typeOfProject, heading, description } = card */

  return (
    <Article>
      <div className="card">
        <figure>
          <div className="card--display">
            <Img fluid={card.picture.fluid} alt={card.picture.title} />
            <Text>
              <h1 className="Icons">{card.title}</h1>
              <h2> {card.typeOfProject}</h2>
            </Text>
          </div>
          <div className="card--hover">
            <a href={card.adress} target="_blank" rel="noopener noreferrer">
              <h2>{card.heading}</h2>
              <p>{card.description}</p>
              <p className="link" href={card.adress}>
                Click to see project
              </p>
            </a>
          </div>
        </figure>
        <div className="card--border"></div>
      </div>
    </Article>
  )
}

export default Card
const Article = styled.article`
  font-weight: bold;
  position: relative;
  height: 300px;
  width: 400px;

  background: linear-gradient(270deg, #510f66, #9e587d, #00e0ff);
  padding: 6px;
  animation: Invader 15s ease infinite;
  background-size: 200%;
  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    height: 101%;
    width: 101%;
    margin: 0 auto;
    filter: blur(20px);
    background: linear-gradient(270deg, #510f66, #9f209e, #00e0ff);
  }
  @keyframes Invader {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .card {
    font-weight: bold;
    height: 100%;
    max-height: 100%;

    figure {
      box-shadow: 1px 5px 7px rgba(0, 0, 0, 0.5);
      box-sizing: border-box;
      height: 100%;
      border: 2px solid #292929;
      background: #292929;
      text-decoration: none;
      color: white;
      display: block;
      transition: 0.25s ease;
      .gatsby-image-wrapper {
      }
      &:hover {
        border-color: purple;

        .card--display {
          display: none;
        }

        .card--hover {
          display: block;
        }
      }
      .card--display {
        padding: 5px;

        text-align: center;

        h1 {
          font-size: 3rem;
        }
      }

      .card--hover {
        display: none;
        padding: 15px;

        h2 {
          margin: 10px 0;
          color: inherit;
        }

        p {
          font-weight: normal;
          line-height: 1.5;
          color: inherit;
        }
        .link {
          margin: 20px 0 0;
          font-weight: bold;
          color: #00b1d2;
          line-height: 1.5;
        }
      }
    }
  }
  @media (max-width: 400px) {
    max-width: 400px;
    width: 350px;
  }
  @media (max-width: 350px) {
    width: 320px;
  }
`

const Text = styled.div`
  color: inherit;
  margin-top: 10px;
  h1 {
    color: inherit;
  }
  h2 {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: inherit;
  }
`
