import React from "react"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"
import Card from "./Card"

const getCards = graphql`
  query {
    card: allContentfulProject(sort: { fields: createdAt }) {
      edges {
        node {
          id
          title
          heading
          adress
          contentful_id
          description
          typeOfProject
          picture {
            title
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`
const Gallery = () => {
  return (
    <StaticQuery
      query={getCards}
      render={data => {
        return (
          <>
            <Grid>
              {data.card.edges.map(({ node: card }) => {
                return <Card key={card.id} card={card} />
              })}
            </Grid>
          </>
        )
      }}
    />
  )
}

export default Gallery
const Grid = styled.div`
  margin: 60px auto;
  display: grid;
  justify-content: space-around;
  justify-items: center;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  box-sizing: border-box;
  gap: 30px 30px;
`
